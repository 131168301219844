<template>
  <div/>
</template>

<script>
import Request from '../../helpers/request'

export default {
  mixins: [Request],
  data () {
    return {}
  },
  async beforeMount () {
    if (this.$route.query.token) {
      try {
        await this.login()
        await this.$router.push('/')
        window.location.reload()
      } catch (error) {
        setTimeout(async () => {
          await this.$router.push('/')
          window.location.reload()
        }, 3000)
      }
    }
  },
  methods: {
    login () {
      let body = {
        data: {
          token: this.$route.query.token
        }
      }

      return new Promise((resolve, reject) => {
        this.request('POST', '/one-time-login/token', body, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
